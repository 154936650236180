import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Fab from "@mui/material/Fab";

let enableNewUI = process.env.REACT_APP_NEWUI_LW;

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function BarBottom() {
  return (
    <React.Fragment>
      {enableNewUI == 1 ? (
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 0,
            top: "auto",
            bottom: 0,
            border: "7px solid #cea052",
            backgroundColor: "#0a4875",
            height: "45px",
            zIndex: 99,
          }}
        >
          <Toolbar>
            <StyledFab style={{ width: "fit-content" }} id="nohover" disableautofocus disableenforcefocus>
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}
