import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import emptyImg from "../../../assets/images/empty-img.png";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { Stack } from "@mui/material";

const showImage = process.env.REACT_APP_SHOW_IMAGE_SRC;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const site = process.env.REACT_APP_SITE;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

export default function FoodCard(props) {
  const isMobile = useMediaQuery("(max-width:600px)");
  console.log(showImage, "showimage");
  let current_url = window.location.href;
  current_url = current_url.split("/");

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      margin: theme.spacing(2),
      height: "auto",
      position: "relative",
      padding: {
        sm: 0,
      },
      backgroundColor: "white",
      cursor: "pointer",
      borderBottom: isMobile && "2px solid #f2f2f2",
      border: !isMobile && "2px solid #f2f2f2",
      borderRadius: "8px",
    },
    details: {
      // display: "flex",
      // flexDirection: "column",
      width: isMobile ? "100%" : "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      webkitLineClamp: "3" /* number of lines to show */,
      webkitBoxOrient: "vertical",
    },
    price: {
      position: "absolute",
      bottom: "5px",
      color: "black",
    },
    content: {
      // flex: "0 1 auto",
      width: "100%",
      wordWrap: "break-word",
      overflow: "hidden",
      lineClamp: 2,
    },
    cover: {
      width: isMobile ? "250px" : "200px",
      position: "relative",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <Card
        className={classes.root + " bg-white"}
        square
        elevation={0}
        onClick={() => {
          const productBaseUrl =
            current_url[3] !== "menu" ? props.categoryName : "menu";
          props.getSelectedProduct(props.product.ItemID);

          history.push({
            pathname:
              "/" +
              productBaseUrl +
              "/" +
              props.product.ItemName.replace(/[^a-zA-Z0-9]+/g, "-"),
            state: {
              revenueCenter: props.revenueCenter,
              menuName: props.menuName,
            },
          });
        }}
      >
        <CardContent className={classes.content}>
          <Stack
            justifyContent="space-between"
            sx={{
              height: "110px",
            }}
          >
            <div>
              <Typography
                component="h4"
                variant="subtitle1"
                style={{
                  fontWeight: 500,
                }}
              >
                {props.product.ItemName}
              </Typography>
              <Typography
                style={{
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "4",
                  overflow: "hidden",
                  "-webkit-box-orient": "vertical",
                  lineHeight: 1.2,
                }}
                variant="subtitle2"
                color="textSecondary"
              >
                {isMobile
                  ? props.product.ItemDescription.length > 50
                    ? props.product.ItemDescription.substring(0, 50 - 3) + "..."
                    : props.product.ItemDescription.substring(0, 50)
                  : props.product.ItemDescription.length > 70
                  ? props.product.ItemDescription.substring(0, 70 - 3) + "..."
                  : props.product.ItemDescription.substring(0, 70)}
              </Typography>
            </div>
            <div>
              <Typography component="h4" variant="h5">
                $ {numberWithCommas(props.product.DefaultPrice / 100)}
              </Typography>
            </div>
          </Stack>
        </CardContent>
        {showImage == 1 ? (
          <CardMedia className={classes.cover} title={props.product.ItemName}>
            <div
              style={{
                position: "relative",
              }}
            >
              <img
                src={
                  props.product.FileName && props.product.FileName.length > 0
                    ? siteImgSrc + encodeURIComponent(props.product.FileName)
                    : showImage == 0
                    ? null
                    : emptyImg
                }
                style={{
                  borderRadius: "4px",
                }}
                onError={(error) => {
                  //replacement of broken Image
                  error.target.src = showImage === 0 ? null : emptyImg;
                }}
              />
              <span
                className="foodCardPlusSign"
                style={{
                  background: "#cc3333",
                  color: "white",
                  position: "absolute",
                  // bottom: "2px",
                  // right: "2px",
                  top: "90px",
                  left: "85px",
                  width: "24px",
                  height: "24px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "8px",
                  border: "1px solid white",
                }}
              >
                <FaPlus fontSize=".5rem" />
              </span>
            </div>
          </CardMedia>
        ) : null}
      </Card>
    </>
  );
}
