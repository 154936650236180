import React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import { useHistory, useLocation } from 'react-router-dom';

const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
  height: theme.spacing(3),
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
      0.06
    ),
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
      0.12
    ),
  },
}));

export default function CustomizedBreadcrumbs() {
  const history = useHistory();
  const { state } = useLocation();
  const { menuName, revenueCenter } = state || {};

  const handleBreadcrumbClick = (path, newState) => {
    history.push({
      pathname: path,
      state: {
        ...state,
        ...newState,
      },
    });
  };

  return (
    <div role="presentation" className="breadcrumbs">
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: '10px 26px 0' }}>
        <StyledBreadcrumb
          component="a"
          label="Menu"
          icon={<RestaurantRoundedIcon fontSize="small" />}
          onClick={() => handleBreadcrumbClick('/welcome', { menuName: '', revenueCenter: '' })}
        />

        {revenueCenter && (
          <StyledBreadcrumb
            component="a"
            label={revenueCenter}
            onClick={() => handleBreadcrumbClick('/welcome', { menuName: '' })}
          />
        )}

        {menuName && <StyledBreadcrumb component="a" label={menuName} />}
      </Breadcrumbs>
    </div>
  );
}
